import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax, Sine } from 'gsap/TweenMax';

// Icons
import Spinner from 'assets/icons/spinner.png';

// Styles
import styles from './loader.module.scss';

class Loader extends PureComponent {
  constructor(props) {
    super(props);
    this.loaderTl = new TimelineMax({ paused: true });
    this.loader = React.createRef();
    this.innerSpinner = React.createRef();
  }

  componentDidMount() {
    const { current: loader } = this.loader;
    const { current: innerSpinner } = this.innerSpinner;

    // Timelines
    // Loader
    this.loaderTl
      .add('enter')
      .set(loader, { x: 0, alpha: 1, zIndex: 20 })
      .set(innerSpinner, { scale: 1 })
      .add('exit')
      .to(innerSpinner, 0.3, { scale: 0, ease: Sine.easeOut }, 0.5)
      .to(loader, 0.2, { alpha: 0, ease: Sine.easeOut }, 0.7)
      .add('out');
  }

  componentDidUpdate() {
    const { isLoading } = this.props;
    if (isLoading) {
      this.loaderTl.tweenFromTo(0, 'exit');
    } else {
      this.loaderTl.tweenTo('out');
    }
  }

  render() {
    return (
      <div ref={this.loader} className={styles.loader}>
        <div ref={this.innerSpinner}>
          <img src={Spinner} alt="spinner" />
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;
