import React, { Component } from 'react';

// Styles
import styles from './prefooter.module.scss';

class AppPromotion extends Component {
  trackAppPromoClick(type) {
    console.log(type);
    window.dataLayer = window.dataLayer || [];    
    window.dataLayer.push({ 'event': 'app_store_click', 'platform_type': type });
  }

  render() {
    const appStore = '/images/apple-app-store.svg';
    const playStore = '/images/google-play.svg';

    return (
      <div className={`hideInApp ${styles.promotion}`}>
        <div className={styles.promotionText}>
          <span>Download gratis app</span><br/>
          Tag AB18-Guiden med dig på farten
        </div>
        <div className={styles.promotionLinks}>
          <a
            href="https://itunes.apple.com/us/app/ab18-guiden/id1452199697?mt=8"
            onClick={ () => this.trackAppPromoClick('iOS')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStore} alt="Hent i Apple app store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=dk.kammeradvokaten.absystem"
            onClick={ () => this.trackAppPromoClick('Android')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={playStore} alt="Nu på Google Play" />
          </a>
        </div>
      </div>
    );
  }
}

export default AppPromotion;
