// Lists
export const frontpageList = {
  'ab18': '/list/ab18',
  'abr18': '/list/abr18',
  'abt18': '/list/abt18',
  'Appendikser': '/appendikser',
  'AB og ABR Forenklet': '/forenklet',
  'Betænkning': '/betaenkning',
  'Gamle AB-regler': '/gamle-regler',
  'Paragrafnøgler': '/keys',
};

export const lists = [
  'ab18',
  'abr18',
  'abt18',
  'app18f',
  'app18g',
  'app18h',
  'app18i',
  'absimple',
  'abrsimple',
  'ab92',
  'abt93',
  'abr89',
];

export const reports = {
  chapterA: true,
  chapterB: true,
  ab18: true,
  abr18: true,
  abt18: true,
  app18f: true,
  app18g: true,
  app18h: true,
  app18i: true,
  absimple: true,
  abrsimple: true,
  abfuture: true,
};

export const chapterToDocRef = {
  'C2': '/list/ab18',
  'D2': '/list/abr18',
  'E2': '/list/abt18',
  'F2': '/list/app18f',
  'G2': '/list/app18g',
  'H2': '/list/app18h',
  'I2': '/list/app18i',
  'J2': '/list/absimple',
  'K2': '/list/abrsimple',
};

// paragraph keys
export const paragraphKeys = {
  'AB18 <> AB92': '/keys/ab18ab92',
  'AB92 <> AB18': '/keys/ab92ab18',
  'ABR18 <> ABR89': '/keys/abr18abr89',
  'ABR89 <> ABR18': '/keys/abr89abr18',
  'ABT18 <> ABT93': '/keys/abt18abt93',
  'ABT93 <> ABT18': '/keys/abt93abt18',
  'AB18 <> ABR18 <> ABT18': '/keys/ab18abr18abt18',
};

// forenklet
export const simplified = {
  'AB Forenklet': '/list/absimple',
  'ABR Forenklet': '/list/abrsimple',
};

// appendencies
export const appendencies = {
  'APP Projektudvikling': '/list/app18f',
  'APP Projektoptimering': '/list/app18g',
  'APP Driftskrav': '/list/app18h',
  'APP Incitamenter': '/list/app18i',
};

// legacy rules
export const oldRules = [
  { name: 'ABT 93', route: '/list/abt93' },
  { name: 'AB 92', route: '/list/ab92' },
  { name: 'ABR 89', route: '/list/abr89' },
];

// General Routes
export const routes = {
  'AB18-guiden': '/',
  'Paragrafnøgler': '/keys',
};

// Secondary Routes
export const secondRoutes = {
  'Om guiden': '/about',
  'Vilkår og betingelser': '/betingelser',
};
