// Legacy
const formatAddendum = addendum => {
  if (addendum) {
    return {
      ...addendum,
      domId: addendum.id.split('.').join('-'),
    };
  }
  return null;
};

const formatLegacyPart = part => {
  const domId = `ch${part.id.split('.').join('-')}`;
  return {
    ...part,
    domId,
    addendum: formatAddendum(part.addendum),
  };
};

const formatLegacyParagraph = paragraph => {
  const domId = `ch${paragraph.id.split('.').join('-')}`;
  const domIdHeader = `ch${paragraph.id.split('.').join('-')}_header`;
  return {
    ...paragraph,
    domId,
    domIdHeader,
    addendum: formatAddendum(paragraph.addendum),
    parts: paragraph.parts.map(formatLegacyPart),
  };
};

const formatLegacyChapter = chapter => {
  const domId = `ch${chapter.id}`;
  return {
    ...chapter,
    domId,
    paragraphs: chapter.paragraphs.map(formatLegacyParagraph),
  };
};

// new
const formatPart = paDomId => part => {
  const domId = `${paDomId}_pt${part.id}`;
  return {
    ...part,
    domId,
  };
};

const formatParagraph = prefix => paragraph => {
  const domId = `${prefix}_pa${paragraph.paragraph}`;
  return {
    ...paragraph,
    domId,
    parts: paragraph.parts.map(formatPart(domId)),
  };
};

const formatSection = chapterId => section => {
  const domId = `ch${chapterId}_se${section.id}`;
  return {
    ...section,
    domId,
    paragraphs: section.paragraphs.map(formatParagraph(domId)),
  };
};

export const formatResult = (result, docId) => {
  if (docId === 'abr89') {
    return {
      ...result,
      chapters: result.chapters.map(formatLegacyChapter),
    };
  }
  return {
    ...result,
    sections: result.sections.map(formatSection(result.chapterId)),
  };
};
