const FETCH_LIST = 'lists/FETCH_LIST';
const FETCH_LIST_ERROR = 'lists/FETCH_LIST_ERROR';
const FETCH_LIST_INIT = 'lists/FETCH_LIST_INIT';
const RESET_SEARCH = 'lists/RESET_SEARCH';
const SET_SEARCH_VALUE = 'lists/SET_SEARCH_VALUE';

export default {
  FETCH_LIST,
  FETCH_LIST_ERROR,
  FETCH_LIST_INIT,
  RESET_SEARCH,
  SET_SEARCH_VALUE,
};
