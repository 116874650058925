import React from 'react';
import PropTypes from 'prop-types';

import styles from './inner.module.scss';

const Inner = ({ variant, children }) => {
  return <div className={styles[variant] || styles.auto}>{children}</div>;
};

Inner.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default Inner;
