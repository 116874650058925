import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import smoothscroll from 'smoothscroll-polyfill';

// Actions
import { appOperations } from 'state/ducks/app';

// Components
import Header from 'views/components/Header';
import Loader from 'views/components/Loader';
import ScrollToTop from 'views/components/ScrollToTop';

// Utilities
import history from 'utilities/router';
import { reports } from 'constants/routes';

// Styles
import styles from './app.module.scss';
import Prefooter from '../../components/Prefooter';

// Containers
const Frontpage = Loadable({
  loader: () => import('views/containers/Frontpage/Frontpage'),
  loading: () => null,
});

const List = Loadable({
  loader: () => import('views/containers/List/List'),
  loading: () => null,
});

const About = Loadable({
  loader: () => import('views/containers/About/About'),
  loading: () => null,
});

const Terms = Loadable({
  loader: () => import('views/containers/Terms/Terms'),
  loading: () => null,
});

const Contact = Loadable({
  loader: () => import('views/containers/Contact/Contact'),
  loading: () => null,
});

const Keys = Loadable({
  loader: () => import('views/containers/Keys/Keys'),
  loading: () => null,
});

const Key = Loadable({
  loader: () => import('views/containers/Key/Key'),
  loading: () => null,
});

const Appendencies = Loadable({
  loader: () => import('views/containers/Appendencies/Appendencies'),
  loading: () => null,
});

const Simplified = Loadable({
  loader: () => import('views/containers/Simplified/Simplified'),
  loading: () => null,
});

const Report = Loadable({
  loader: () => import('views/containers/Report/Report'),
  loading: () => null,
});

const Oldrules = Loadable({
  loader: () => import('views/containers/Oldrules/Oldrules'),
  loading: () => null,
});

const ReportDocument = Loadable({
  loader: () => import('views/containers/ReportDocument/ReportDocument'),
  loading: () => null,
});

// Components
const NotFound = Loadable({
  loader: () => import('views/components/NotFound'),
  loading: () => null,
});

const Footer = Loadable({
  loader: () => import('views/components/Footer'),
  loading: () => null,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { location: history.location };
  }

  componentDidMount() {
    // Add Polyfill for SmoothScroll
    smoothscroll.polyfill();

    // Preload
    Frontpage.preload();
    List.preload();
    NotFound.preload();
    this.startListeningToHistoryChanges();
    this.addListenerToOutgoingLinks();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.state;
    const prevLocation = prevState.location;

    if (location !== prevLocation) {
      setTimeout(() => {
        const pagetitle = document.getElementsByTagName('h1');
        let title = '';
        if (pagetitle && pagetitle.length > 0) title = pagetitle[0].innerText;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          page_location: window.location.href,
          page_title: title,
        });
      }, 100)
    }
  }

  addListenerToOutgoingLinks() {
    document.body.addEventListener('click', (e) => {
      // eslint-disable-next-line
      const regex = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?(?<domain>[^:\/?\n]+)/, "igm");
      if (e.target && e.target.nodeName === 'A') { 
        if (e.target.href !== '') {
          const hostdom = regex.exec(e.target.href);
          if (hostdom.groups.domain !== window.location.hostname) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'outbound_link_click',
              page_location: e.target.href,
            });
          }
        }
      } else if (e.target && e.target.nodeName === 'IMG') {
        if (e.target.parentNode.nodeName === 'A' && e.target.parentNode.href !== '') {
          const hostdom = regex.exec(e.target.parentNode.href);
          if (hostdom.groups.domain !== window.location.hostname) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'outbound_link_click',
              page_location: e.target.parentNode.href,
            });
          }
        }
      }
    });
  }

  startListeningToHistoryChanges() {   
    setTimeout(() => {
      const pagetitle = document.getElementsByTagName('h1');
      if (pagetitle.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          page_location: window.location.href,
          page_title: pagetitle[0].innerText,
        });
      }
    }, 500)


    // Listen to history changes.
    // You can unlisten by calling the constant (`unlisten()`).
    history.listen((location, action) => {
       // eslint-disable-next-line
      this.setState({ location: location });
    });
  }

  render() {
    const {
      menuOpen,
      toggleMenu,
      isLoading,
    } = this.props;

    return (
      <>
        <Loader isLoading={isLoading} />
        <Router history={history}>
          <ScrollToTop>
            <div className={styles.app}>
              <Header menuOpen={menuOpen} toggleMenu={toggleMenu} />
              <main className={styles.main}>

                <Switch>
                  <Route path="/list/:id" render={props => <List {...props} />} />
                  <Route path="/keys/:id" render={props => <Key {...props} />} />
                  <Route path="/keys" render={props => <Keys {...props} />} />
                  <Route path="/" render={props => <Frontpage {...props} />} exact />
                  <Route path="/about" render={props => <About {...props} />} exact />
                  <Route path="/betingelser" render={props => <Terms {...props} />} exact />
                  <Route path="/kontakt" render={props => <Contact {...props} />} exact />
                  <Route path="/appendikser" render={props => <Appendencies {...props} />} />
                  <Route path="/forenklet" render={props => <Simplified {...props} />} />
                  <Route path="/gamle-regler" render={props => <Oldrules {...props} />} />
                  <Route path="/betaenkning" render={props => <ReportDocument {...props} />} />
                  <Route render={props => <NotFound {...props} />} />
                </Switch>
              </main>
              <Prefooter />
              <Footer />
              <Route
                path="/list/:id"
                render={
                  props => {
                    const { match: { params: { id } } } = props;
                    if (reports[id]) {
                      return (<Report {...props} />);
                    }
                    return null;
                  }
                }
              />
            </div>
          </ScrollToTop>
        </Router>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.app.isLoading,
  menuOpen: state.app.menu.open,
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(appOperations.toggleMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
