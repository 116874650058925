// Types
import types from './types';
import { formatResult } from './helpers';

export const fetchListAction = (id, dispatch) => (
  fetch(`/lists/${id}.json`)
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: types.FETCH_LIST,
        payload: formatResult(res, id),
        meta: {
          id,
        },
      });
    })
);

export const fetchListError = (id, error) => ({
  type: types.FETCH_LIST_ERROR,
  payload: error,
  meta: {
    id,
  },
});

export const fetchListInit = id => ({
  type: types.FETCH_LIST_INIT,
  meta: {
    id,
  },
});

export const setSearchValue = value => ({
  type: types.SET_SEARCH_VALUE,
  payload: value,
});

export const resetSearch = () => ({
  type: types.RESET_SEARCH,
});

export default {
  fetchListInit,
  setSearchValue,
  resetSearch,
};
