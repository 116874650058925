import types from './types';

export const toggleMenu = () => ({
  type: types.TOGGLE_MENU,
});

export const setLoading = bool => ({
  type: types.SET_LOADING,
  payload: bool,
});

export const setIsMobile = bool => ({
  type: types.SET_IS_MOBILE,
  payload: bool,
});

export default {
  toggleMenu,
  setLoading,
  setIsMobile,
};
