import 'react-app-polyfill/ie11';
import './polyfills/array';
import './polyfills/object';
import './polyfills/string';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import queryString from 'query-string';

// Utils
import { store } from 'state/store';

// Container
import App from 'views/containers/App/App';

// Styles
import 'assets/styles/critical.crit.scss';
import 'assets/styles/main.scss';

// Params handler
const params = queryString.parse(window.location.search);
window.app = params.app === '1';
window.track = params.track;

function initCookiebotTracking() {
  if (window.app) {
    if (window.track === '0') {
      window.Cookiebot.submitCustomConsent(false, false, false);
      window.Cookiebot.hide();
    } else if (window.track === '1') {
      window.Cookiebot.submitCustomConsent(true, true, true);
      window.Cookiebot.hide();
    }
  }
}

if (window.app) {
  initCookiebotTracking();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.register();
