// Types
import types from './types';

const chapterMap = {
  'ab18': {
    id: 'C2',
    sections: [1, 9, 12, 34, 39, 44, 47, 56, 59, 64, 1000],
  },
  'abr18': {
    id: 'D2',
    sections: [1, 8, 9, 33, 37, 38, 42, 52, 55, 59, 1000],
  },
  'abt18': {
    id: 'E2',
    sections: [1, 9, 12, 32, 37, 42, 45, 54, 57, 62, 1000],
  },
  'ab92': {
    id: 'Bilag1',
    sections: [1, 6, 9, 22, 24, 28, 30, 37, 40, 45, 1000],
  },
  'abt93': {
    id: 'Bilag3',
    sections: [1, 6, 9, 22, 24, 28, 30, 37, 40, 45, 1000],
  },
};

const formatLegacyDomId = (title) => {
  const mainId = title.split(', ')[0];
  return `ch${mainId.split('.').join('-')}`;
};

const getSectionIndex = (sections, paragraph) => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i] > paragraph) return i;
  }
  return -1;
};

const formatDefaultDomId = (title, doc) => {
  const { sections } = chapterMap[doc];

  const paragraph = title.match(/\d+/);
  const partCut = title.match(/(stk.{0,2}\d+)/);
  const part = partCut ? partCut[0].match(/\d+/) : null;

  const section = getSectionIndex(sections, paragraph);
  let domId = `ch${chapterMap[doc].id}_se${section}_pa${paragraph}`;
  if (part) domId = `${domId}_pt${part}`;
  return domId;
};

const formatDomId = (title, doc) => {
  switch (doc) {
    case 'abr89':
      if (!title || title.indexOf('.') === -1) return '';
      return formatLegacyDomId(title);
    default:
      if (!title || title.indexOf('§') === -1) return '';
      return formatDefaultDomId(title, doc);
  }
};

const formatKey = (leftDoc, centerDoc, rightDoc) => (key, index) => {
  return {
    ...key,
    domIdLeft: formatDomId(key.leftParagraph, leftDoc),
    domIdCenter: formatDomId(key.centerParagraph, centerDoc),
    domIdRight: formatDomId(key.rightParagraph, rightDoc),
    uniqueKey: index,
  };
};

const formatResult = result => {
  return {
    ...result,
    keys: result.keys.map(
      formatKey(result.leftTitle.toLowerCase(), result.centerTitle.toLowerCase(), result.rightTitle.toLowerCase())
    ),
  };
};

export const fetchListAction = (id, dispatch) => (
  fetch(`/paragraphkeys/${id}.json`)
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: types.FETCH_LIST,
        payload: formatResult(res),
        meta: {
          id,
        },
      });
    })
);

export const fetchListError = (id, error) => ({
  type: types.FETCH_LIST_ERROR,
  payload: error,
  meta: {
    id,
  },
});

export const fetchListInit = id => ({
  type: types.FETCH_LIST_INIT,
  meta: {
    id,
  },
});

export default {
  fetchListInit,
};
