/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class AppLink extends Component {
  render() {
    const {
      onClick,
      to,
      children,
      className,
    } = this.props;
    const isApp = window.app;

    if (isApp) {
      let path;
      if (typeof to === 'string') {
        path = {
          pathname: to,
          search: '?app=1',
        };
      } else if (typeof to === 'object') {
        path = to;
        if (path.search === undefined || path.search === '') {
          path.search = '?app=1';
        } else {
          path.search = `${path.search}&app=1`;
        }
      } else {
        path = to;
      }

      return <Link className={className} onClick={onClick} to={path}>{children}</Link>;
    }

    return <Link className={className} onClick={onClick} to={to}>{children}</Link>;
  }
}

export default AppLink;