import { setLoading } from 'state/ducks/app/actions';
import {
  formatResult, extractRemarks,
} from './helpers';

const FETCH_REPORT = 'reports/FETCH_REPORT';
const FETCH_REPORT_ERROR = 'reports/FETCH_REPORT_ERROR';
const FETCH_REPORT_INIT = 'reports/FETCH_REPORT_INIT';
const REPORT_SET_CURRENT_SELECTION = 'reports/REPORT_SET_CURRENT_SELECTION';
const REPORT_RESET_SEARCH = 'reports/REPORT_RESET_SEARCH';
const REPORT_SET_SEARCH_VALUE = 'reports/REPORT_SET_SEARCH_VALUE';

const initialState = { selection: null, searchValue: '' };

const fetchReport = (filename, dispatch) => (
  fetch(`/reports/${filename}reportdoc.json`)
    .then(res => res.json())
    .then(res => {
      const formattedResult = formatResult(res);
      const remarks = extractRemarks(formattedResult);
      const payload = {
        ...formattedResult,
        ...remarks,
      };

      dispatch({
        type: FETCH_REPORT,
        payload,
        meta: {
          filename,
        },
      });
    })
);

const fetchReportError = (filename, error) => ({
  type: FETCH_REPORT_ERROR,
  payload: error,
  meta: {
    filename,
  },
});

const fetchReportInit = filename => ({
  type: FETCH_REPORT_INIT,
  meta: {
    filename,
  },
});

export const getReport = filename => dispatch => {
  dispatch(fetchReportInit(filename));
  fetchReport(filename, dispatch)
    .catch(error => {
      dispatch(fetchReportError(filename, error));
    });
};

export const getAllReports = filenames => dispatch => {
  dispatch(setLoading(true));
  return Promise.all(filenames.map(filename => {
    dispatch(fetchReportInit(filename));
    return fetchReport(filename, dispatch)
      .catch(error => {
        dispatch(fetchReportError(filename, error));
      });
  })
  ).then(() => dispatch(setLoading(false)));
};

export const setCurrentSelection = (params) => ({
  type: REPORT_SET_CURRENT_SELECTION,
  payload: params,
});

export const resetSearch = () => ({
  type: REPORT_RESET_SEARCH,
});

export const setSearchValue = value => ({
  type: REPORT_SET_SEARCH_VALUE,
  payload: value,
});

const reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case FETCH_REPORT:
      return {
        ...state,
        [meta.filename]: {
          isFetching: false,
          error: false,
          ...payload,
        },
      };

    case FETCH_REPORT_INIT:
      return {
        ...state,
        [meta.filename]: {
          isFetching: true,
        },
      };

    case FETCH_REPORT_ERROR:
      return {
        ...state,
        [meta.filename]: {
          isFetching: false,
          error: true,
        },
      };

    case REPORT_SET_CURRENT_SELECTION:
      return {
        ...state,
        currentSelection: payload,
      };

    case REPORT_RESET_SEARCH:
      return {
        ...state,
        searchValue: '',
      };

    case REPORT_SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
