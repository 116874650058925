import { setLoading } from 'state/ducks/app/actions';
import {
  fetchListAction,
  fetchListError,
  fetchListInit,
  resetSearch,
  setSearchValue,
} from 'state/ducks/lists/actions';

const fetchList = id => dispatch => {
  // Set the global loading state that we're starting to fetch
  dispatch(fetchListInit(id));
  dispatch(setLoading(true));
  // Fetch the data
  fetchListAction(id, dispatch)
    .then(() => {
      // Set the global loading state that we're done fetching
      dispatch(setLoading(false));
    })
    .catch(error => {
      // If error, then set the error in the store
      dispatch(fetchListError(id, error));
      console.warn(`The requests failed with the follow error: ${error}`); // eslint-disable-line
    });
};

const fetchLists = lists => dispatch => {
  dispatch(setLoading(true));
  return Promise.all(lists.map(list => (
    fetchListAction(list, dispatch)
  ))).then(() => {
    dispatch(setLoading(false));
  });
};

export default {
  fetchList,
  fetchLists,
  setSearchValue,
  resetSearch,
};
