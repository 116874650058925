import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';

// Importing Middlewares
import { middlewares } from 'utilities/middlewares/middlewares';
import * as reducers from './ducks/index'; // import all reducers from ducks/index.js

const rootReducer = combineReducers(reducers);

// Configure The Store
const configureStore = (initialState = {}) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const middleware = applyMiddleware(...middlewares);

  // If Production
  if (isProduction) {
    return createStore(rootReducer, middleware);
  }

  // Enable DevTools if browser extension is installed
  let enhancer;
  if (window.__REDUX_DEVTOOLS_EXTENSION__) { // eslint-disable-line
    enhancer = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    );
  } else {
    enhancer = compose(middleware);
  }

  return createStore(
    rootReducer,
    initialState,
    enhancer
  );
};

const store = configureStore(window.__INITIAL_STATE__); // eslint-disable-line

export { store };
