import { combineReducers } from 'redux';
import types from './types';

const keys = (state = { searchValue: '' }, action) => {
  const { payload, meta } = action;
  switch (action.type) {
    case types.FETCH_LIST_INIT: {
      return {
        ...state,
        [meta.id]: {
          isFetching: true,
          error: false,
        },
      };
    }
    case types.FETCH_LIST_ERROR: {
      return {
        ...state,
        [meta.id]: {
          isFetching: false,
          error: true,
          errorMessage: payload.toString(),
        },
      };
    }
    case types.FETCH_LIST: {
      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          isFetching: false,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

const listsReducer = combineReducers({
  keys,
});

export default listsReducer;
