import { chapterToDocRef } from 'constants/routes';
import {
  generateChapterId,
  generateSectionId,
  generateParagraphId,
  generatePartId,
} from 'utilities/reportHelper';

export const flattenText = (currentValue, bodyItem) => {
  switch (bodyItem.type) {
    case 'heading':
      return `${currentValue} ${bodyItem.heading}`;
    case 'text':
      return `${currentValue} ${bodyItem.headline || ''} ${bodyItem.text}`;
    case 'list':
      return bodyItem.list.reduce(flattenText, currentValue);
    default:
      return currentValue;
  }
};

export const extractRemarks = chapter => {
  const { chapterRemarks, sections, chapterId } = chapter;
  const allParts = {};
  const flat = {};
  if (chapterRemarks && chapterRemarks.body) {
    allParts[generateChapterId(chapterId)] = chapterRemarks;
    flat[chapterRemarks.domId] = chapterRemarks.body.reduce(flattenText, '');
  }
  if (sections) {
    sections.forEach((section, index) => {
      const sectionId = index + 1;
      if (section.generalRemarks) {
        allParts[generateSectionId(sectionId)] = section.generalRemarks;
        flat[section.generalRemarks.domId] = section.generalRemarks.body.reduce(
          flattenText,
          ''
        );
      }
      if (section.paragraphs) {
        section.paragraphs.forEach(paragraph => {
          if (!paragraph.ignoreFromReport) {
            if (paragraph.paragraphRemarks) {
              allParts[generateParagraphId(sectionId, paragraph.paragraphId)] =
                paragraph.paragraphRemarks;
              flat[paragraph.domId] = paragraph.paragraphRemarks.body.reduce(
                flattenText,
                ''
              );
            }
            if (paragraph.parts) {
              paragraph.parts.forEach(part => {
                if (!part.ignoreFromReport) {
                  allParts[
                    generatePartId(sectionId, paragraph.paragraphId, part.partId)
                  ] = part;
                  flat[part.domId] = part.body.reduce(flattenText, '');
                }
              });
            }
          }
        });
      }
    });
  }
  return { allParts, flat };
};

const formatPart = (paDomId, docRef) => part => {
  const domId = `${paDomId}_pt${part.partId}`;
  return {
    ...part,
    domId,
    docRef,
  };
};

const formatParagraph = (seDomId, docRef) => paragraph => {
  const domId = `${seDomId}_pa${paragraph.paragraphId}`;
  return {
    ...paragraph,
    domId,
    docRef,
    parts: paragraph.parts
      ? paragraph.parts.map(formatPart(domId, docRef))
      : null,
    paragraphRemarks: paragraph.paragraphRemarks
      ? {
        ...paragraph.paragraphRemarks,
        domId,
      }
      : null,
  };
};

const formatSections = chapterId => (section, index) => {
  const domId = `ch${chapterId}_se${index + 1}`;
  const docRef = chapterToDocRef[chapterId];
  return {
    ...section,
    domId,
    paragraphs: section.paragraphs
      ? section.paragraphs.map(formatParagraph(domId, docRef))
      : null,
    generalRemarks: section.generalRemarks
      ? {
        ...section.generalRemarks,
        domId: `${domId}_gr`,
      }
      : null,
  };
};

export const formatResult = result => {
  const chapterRemarks = result.chapterRemarks
    ? {
      ...result.chapterRemarks,
      domId: `ch${result.chapterId}_cr`,
    }
    : null;
  return {
    ...result,
    chapterRemarks,
    sections: result.sections
      ? result.sections.map(formatSections(result.chapterId))
      : null,
  };
};
