import types from './types';

const defaultState = {
  isLoading: true,
  menu: {
    open: false,
    isMobile: false,
  },
};

const app = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case types.TOGGLE_MENU: {
      return {
        ...state,
        menu: {
          ...state.menu,
          open: !state.menu.open,
        },
      };
    }
    case types.SET_IS_MOBILE: {
      return {
        ...state,
        menu: {
          ...state.menu,
          isMobile: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default app;
